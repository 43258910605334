import Page from "../components/base_components/Page";
import ListContainer from "../components/List/ListContainer";

const TodoListPage = () => {
    return (<Page>
        <ListContainer />
    </Page>)

}

export default TodoListPage;